import { gql } from "@apollo/client";

export const getFindBuildingResult = ({data}) => data?.classMethods?.Building?.findByFormattedAddress || [];
export const findBuildingQuery = gql`
  query getBuildingByFormattedAddress ($address: String) {
    classMethods {
      Building {
        findByFormattedAddress (address: $address) {
          id
          name
          code
          notification
          ewayCountry
          formattedAddress
          publicInfo
          address {
            id
            streetNo
            address
            suburb
            state
            postCode
            countryId
            country {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const getAddressQuery = gql`
  query getAddress ($id: ID!) {
    models {
      Address (where: {
        id: {eq: $id}
      }) {
        edges {
          node {
            id
            streetNo
            address
            suburb
            state
            postCode
          }
        }
      }
    }
  }
`;

export const checkServiceAvailabilityQuery = gql`query checkServiceAvailability($buildingCode: String, $unitNo: String) {
  classMethods {
    Building {
      checkServiceAvailability(buildingCode: $buildingCode, unitNo: $unitNo) {
        unitNo,
        streetNo
        address
        suburb
        state
        postCode
        isNewConnection
        country {
          name
        }
      }
    }
  }
}`;


export const getCountriesQuery = gql`  query getCountries {
  models {
    Country {
        edges {
            node {
                id
                name
            }
        }
    }
  }
}`;
export const getCountriesResult = ({data}) => (data?.models?.Country?.edges || []).map(({node}) => node);

export const getBuildingCIS = gql`
  query getBuildingCIS ($code: String) {
    classMethods {
      Building {
        getBuildingCIS (code: $code)
      }
    }
  }
`;
