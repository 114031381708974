import React from "react";
import {FormattedMessage} from "react-intl";
import {Link, useHistory} from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Panel from "./panel";
import {getFormData, setFormData} from "../../components/manager/form";

import { getProductsQuery, getProductsResult } from "../../components/logic/products";
import { useQuery } from "@apollo/client";


export default function Plan() {
  const data = getFormData();
  const {voucherCode, buildingCode, unitNo} = data;
  const history = useHistory();
  const result = useQuery(getProductsQuery, {
    variables: {
      buildingCode,
      voucherCode: voucherCode,
      showHidden: true,
    },
  });

  if (result.loading) {
    return (<React.Fragment/>);
  }

  const products = getProductsResult(result);

  function setProduct(product) {
    const selectevents = product?.selectEventsActivate || [];
    let addons = [];

    (selectevents || []).forEach((event) => {
      const eventProduct = products.find((p) => (p.name || "").toLowerCase() === (event || "").toLowerCase());
      if (eventProduct) {
        addons.push(eventProduct);
      }
    });

    setFormData({selectedProduct: product, addons});
    history.push("/review");
  }

  // const {formatMessage} = props.intl;
  return (<Panel className="plan">
    <Container fluid className="no-gutters">
      <Row>
        <Col xs={12}>
          <div className="title">
            <FormattedMessage id="register.plan.title" />
          </div>
        </Col>
      </Row>
      <Row >
        <Col xs={12}>
          <div className="plan-content-inner">
            <Container fluid className="product-group">
              {products.filter((p) => !p.hidden && p.type === "internet").map((product) => {
                // const {up, down} = extractRateProfile(product.rateProfile);
                return (
                  <div key={product?.id} className="product-container">
                    <div className="product-title">{product.name}</div>
                    <div className="product-image">
                      {getPlanImage(product.name)}
                    </div>
                    <div className="price-speed">
                      <div className="price" style={{
                        textDecoration: (product.originalValue) ? "line-through" : undefined
                      }}>
                        {`$${product.originalValue ? product.originalValue : product.value}`}
                      </div>
                      <div className="per-month">{"Per Month"}</div>
                      {(product || {}).voucherName && <div className="product-voucher">
                        {`$${product.value} ${product.voucherName}`}
                      </div>}
                      <div className="speed">
                        {product.description}
                      </div>
                    </div>
                    <div className="details">
                      <div>
                        {"Unlimited Data"}
                      </div>
                    </div>

                    <div className="sub-details">
                      <div>
                        {"Typical Evening Speed"}
                      </div>
                      <div>
                        {`${getPlanSpeedLabel(product.name)} Mbps`}
                      </div>
                    </div>
                    <div>
                      <a
                        // eslint-disable-next-line no-script-url
                        href="javascript:;"
                        className="vw-button orange select-btn"
                        onClick={() => {
                          setProduct(product);
                        }}
                      >
                        {"Select"}
                      </a>
                    </div>
                  </div>
                );
              })}
            </Container>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="vn-back">
          <Link to={`/?building=${buildingCode}&unitno=${unitNo}`}>
            <FormattedMessage id={"register.products.back"} />
          </Link>
        </Col>
      </Row>
    </Container>
  </Panel>);
}



export function getPlanSpeedLabelPlan(product) {
  switch (product) {
    case "50/20 Mbps":
      return "50/20";
    case "100/20 Mbps":
      return "100/20";
    case "250/25 Mbps":
      return "250/25";
    case "1000/50 Mbps":
      return "1000/50";
  }
  return "50/20";
}

export function getPlanSpeedLabel(product) {
  switch (product) {
    case "50/20 Mbps":
      return "47.3";
    case "100/20 Mbps":
    case "100/40 Mbps":
      return "96.4";
    case "250/25 Mbps":
    case "250/100 Mbps":
      return "241.0";
    case "1000/50 Mbps":
    case "1000/400 Mbps":
      return "250+";
  }
  return "47.3";
}

export function getPlanImage(product) {
  switch (product) {
    case "50/20 Mbps":
      return (<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 18 18"><path fill="#0065a0" fill-opacity=".3" d="M9.01 15.99l8.8-10.96C17.47 4.77 14.08 2 9 2S.53 4.77.19 5.03l8.8 10.96h.02z"/></svg>);
    case "100/20 Mbps":
    case "100/40 Mbps":
      return (<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 18 18"><path fill="#0065a0" fill-opacity=".3" d="M9.01 15.99l8.8-10.96C17.47 4.77 14.08 2 9 2S.53 4.77.19 5.03l8.8 10.96h.02z"/><path fill="#0065a0" d="M5.24 11.31L9 16l3.76-4.68C12.57 11.17 11.14 10 9 10c-2.14 0-3.57 1.17-3.76 1.31z"/></svg>);
    case "250/100 Mbps":
    case "250/25 Mbps":
      return (<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 18 18"><path fill="#0065a0" fill-opacity=".3" d="M9.01 15.99l8.8-10.96C17.47 4.77 14.08 2 9 2S.53 4.77.19 5.03l8.8 10.96h.02z"/><path fill="#0065a0" d="M3.98 9.75l5.01 6.24L9 16v-.01l5.01-6.24C13.76 9.56 11.85 8 9 8 6.15 8 4.24 9.56 3.98 9.75z"/></svg>);
    case "1000/400 Mbps":
    case "1000/50 Mbps":
      return (<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 18 18"><path fill="#0065a0" fill-opacity=".3" d="M9.01 15.99l8.8-10.96C17.47 4.77 14.08 2 9 2S.53 4.77.19 5.03l8.8 10.96h.02z"/><path fill="#0065a0" d="M2.73 8.19L9 15.99V16l.01-.01 6.27-7.8C14.96 7.94 12.56 6 9 6S3.04 7.94 2.73 8.19z"/></svg>);
    default:
      return (<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 18 18"><path fill="#0065a0" fill-opacity=".3" d="M9.01 15.99l8.8-10.96C17.47 4.77 14.08 2 9 2S.53 4.77.19 5.03l8.8 10.96h.02z"/></svg>);
  }
}

export function extractRateProfile(rateProfile) {
  const matches = /^(\d*)M\/(\d*)M|^(\d*)/g.exec(rateProfile);
  const down = matches[1] || matches[3];
  const up = matches[2];
  return {
    up,
    down,
  };
}
